<template>
  <TosListVuetify
    serviceName="yard"
    entityName="inventoryHandlings"
    :pageTitle="title"
    :headers="headers"
    :excludeButtons="{ add: true }"
    :defaultFilters="defaultFilters"
    :defaultSort="defaultSort"
  >
    <template #customFilters="slotProps">
      <v-container fluid class="ma-0 pa-0">
        <v-row align="center" class="ma-0 pa-0">
          <slot name="filterButtons" v-bind:filters="slotProps.data" />
          <v-col cols="2">
            <v-text-field :label="tLabel('Targa')" v-model="slotProps.data['visits.object_codeLike']" v-if="!excludeButtons.unitNumber" />
          </v-col>
          <v-col cols="2">
            <v-select :label="tLabel('SysType')" v-model="slotProps.data.sysTypeIn" :items="sysTypes" multiple v-if="!excludeButtons.sysTypes" />
          </v-col>
          <v-col cols="2">
            <v-text-field :label="tLabel('Tipo Unit')" v-model="slotProps.data['UV.unit_type_codeLike']" v-if="!excludeButtons.tipoUnit" />
          </v-col>
          <v-col cols="2">
            <v-text-field :label="tLabel('Voyage In/Out')" v-model="slotProps.data.voyagesLike" v-if="!excludeButtons.voyages" />
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template #extraButtons="slotProps">
      <slot name="extraButtons" v-bind:extraButtons="slotProps.data" />
    </template>

    <template v-slot:item.actions="slotProps">
      <div class="text-left">
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <slot name="item-menu" v-bind:item="slotProps.data" />
        </v-menu>
      </div>
    </template>

    <template v-slot:item.actions2="slotProps">
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on">....</v-btn>
        </template>
        <slot name="item-menu" v-bind:item="slotProps.data" />
      </v-menu>
    </template>

    <template v-slot:item.carrierVisitObjectCode="slotProps">
      <div>
        <span></span>
        <span class="text-h6">{{ slotProps.data.carrierVisitObjectCode }} </span>
      </div>
    </template>

  </TosListVuetify>
</template>

<script>
import TosListVuetify from "@/components/anagrafiche/TosListVuetify";
import FormatsMixins from "@/mixins/FormatsMixins";
import YardEnumMixin from "@/mixins/yard/EnumMixin";
import EnumVisualization from "./EnumVisualization.vue";

export default {
  name: "InventoryHandlingTable",
  data() {
    return {};
  },
  props: {
    title: { default: "Inventory Handlings" },
    headers: { type: Array },
    defaultFilters: { type: Object, default: () => {} },
    defaultSort: {
      type: Object,
      default: () => {
        return { field: "data_in", desc: false };
      },
    },
    excludeButtons: {
      default: () => {
        return {};
      },
    },
  },
  created() {
    this.$parent.$on("refreshTable", () => {
      this.$emit("refreshTable");
    });
    this.$parent.initFilters(this.defaultFilters);
  },
  methods: {
    initFilters(filters) {
      if (this.$parent.initFilters) {
        this.$parent.initFilters(filters);
      }
    },
  },
  components: { TosListVuetify, EnumVisualization },
  mixins: [FormatsMixins, YardEnumMixin],
};
</script>

<style scoped>
.bordered {
  min-width: 20px;
  font-weight: bold;
  padding-left: 2px;
  padding-right: 2px;
  border: 1px solid black;
}
</style>
