<template>
  <div>
    <InventoryHandlingTable
      :title="tLabel('Transits')"
      :headers="headers"
      :defaultFilters="defaultFilters"
      :defaultSort="defaultSort"
      :excludeButtons="{ unitNumber: true, sysTypes: true, tipoUnit: true, voyages: true }"
    >
      <template v-slot:filterButtons="{ filters }">
        <v-col cols="2">
          <v-text-field :label="tLabel('Targa')" v-model="filters['visits.object_codeLike']" @input="(val) => (filters['visits.object_codeLike'] = val ? val.toUpperCase() : null)"/>
        </v-col>

         <v-col cols="2">
          <v-text-field :label="tLabel('Unit')" v-model="filters['unitObjectCodeLike']" @input="(val) => (filters['unitObjectCodeLike'] = val ? val.toUpperCase() : null)"/>
        </v-col>

         <v-col cols="2">
          <v-text-field :label="tLabel('Tipo merce')" v-model="filters['goodsObjectCodeLike']" @input="(val) => (filters['goodsObjectCodeLike'] = val ? val.toUpperCase() : null)"/>
        </v-col>

      </template>

      <template v-slot:item-menu="{ item }">
        <v-list>
          <v-list-item @click="stampaInterchange(item)">
            <v-list-item-title class="text-uppercase">{{ tLabel("Stampa") }}</v-list-item-title>
            <v-list-item-action>
              <v-icon color="success" medium>mdi-export</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </template>
    </InventoryHandlingTable>
  </div>
</template>

<script>
import InventoryHandlingTable from "@/components/yard/InventoryHandlingTable";

export default {
  name: "Transits",
  data() {
    return {
      item: null,
      defaultFilters: {transportTypeIn : ['TRU'], visitRoleIn: ['CRR'], statusIn : ['E'] },
      dialogGateOutVisible: false,
      gateSubmitReqObj: {},
      listTruckPlates: [],
      defaultSort: { field: "B.exec_date", desc: true },
      unitTypes: [],
      roroTypes: [],
      sysTypes: null,
      headers: [
        { text: `${this.tLabel("Visit")}`, value: "visit.id" },
        { text: `${this.tLabel("Date")}`, value: "execDate", formatValue: "formatDateTimeFromDate" },
        { text: `${this.tLabel("Direction")}`, value: "direction" },
        { text: `${this.tLabel("Targa")}`, value: "tractorPlate" },
        { text: `${this.tLabel("Driver")}`, value: "driver" },
      ],
    };
  },
  async mounted() {
    this.loadCombo();
  },
  async created() {},

  methods: {
    initFilters(filters) {
    },
    async stampaInterchange(item) {
      console.log("TRANSIT stampaInterchange", JSON.stringify(item));
      try {
        const _callConfig = {
          responseType: "blob",
          headers: {
            "Content-Type": "application/pdf",
            Accept: "*/*",
            Pragma: "no-cache",
          },
        };
        console.lod;
        let handlingId = item.id;
        const url =
          item.direction == "IN"
            ? `${this.$apiConfiguration.BASE_PATH_YARD}reports/interchangeInByHandlingId/` + handlingId
            : `${this.$apiConfiguration.BASE_PATH_YARD}reports/interchangeOutByHandlingId/` + handlingId;
        console.log("URL Stampa; ", url);
        const response = await this.$api.get(url, _callConfig);
        const blob = new Blob([response], { type: "application/pdf;charset=ANSI" });
        const fileURL = window.URL.createObjectURL(blob);
        const fileLink = document.createElement("a");
        fileLink.setAttribute("href", fileURL);
        fileLink.setAttribute("download", "Interchange3.pdf");
        document.body.appendChild(fileLink);
        fileLink.click();
      } catch (e) {
        console.error("Errore Interchange", e);
      }
    },

    async loadCombo() {
    },
  },
  components: { InventoryHandlingTable },
};
</script>
